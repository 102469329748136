import Footer from "./footer";

/* This example requires Tailwind CSS v3.0+ */
export default function ResellCta() {
  return (
    <div className="relative isolate overflow-hidden">
      {/* <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-4xl font-bold tracking-tight text-foreground">
            Take the hassle out of literature reviews
            
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-secondary-foreground">
            With EasySLR, you can automate the most time-consuming parts of the
            process and focus on what you do best. We&rsquo;ll handle the rest.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              href="#contact-us"
              className={buttonVariants({ variant: "default" })}
            >
              Get started
            </Link>
            <a
              href="#features"
              className="text-base font-semibold leading-7 text-foreground"
            >
              Learn more <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
      </div> */}
      {/* <Footer /> */}
      <Footer />
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1024 1024"
        className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2"
        aria-hidden="true"
      >
        <circle
          cx={512}
          cy={512}
          r={512}
          fill="url(#8d958450-c69f-4251-94bc-4e091a323369)"
          fillOpacity="0.7"
        />
        <defs>
          <radialGradient
            id="8d958450-c69f-4251-94bc-4e091a323369"
            cx={0}
            cy={0}
            r={1}
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(512 512) rotate(90) scale(512)"
          >
            <stop stopColor="#7775D6" />
            <stop offset={1} stopColor="#E935C1" stopOpacity={0} />
          </radialGradient>
        </defs>
      </svg> */}
    </div>
  );
}

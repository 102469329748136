import Head from "next/head";
import React from "react";

export default function HeadMeta({
  children,
  title = "EasySLR: Fly Through Reviews",
  description = "Save time and increase your productivity with EasySLR - the SaaS software solution designed for you and your team of researchers.",
  keywords = "Systematic Literature Review, SLR, Systematic Review, SR, Systematic Literature Review Software, SLR Software, Systematic Review Software, SR Software, Systematic Literature Review Tool, SLR Tool, Systematic Review Tool, SR Tool, Systematic Literature Review App, SLR App, Systematic Review App, SR App, Systematic Literature Review Application, SLR Application, Systematic Review Application, SR Application, Systematic Literature Review SaaS, SLR SaaS, Systematic Review SaaS, SR SaaS, Systematic Literature Review Software as a Service, SLR Software as a Service, Systematic Review Software as a Service, SR Software as a Service, Systematic Literature Review Software Solution, SLR Software Solution, Systematic Review Software Solution, SR Software Solution, Systematic Literature Review Software Platform, SLR Software Platform, Systematic Review Software Platform, SR Software Platform",
}: {
  children?: React.ReactNode;
  title?: string;
  description?: string;
  keywords?: string;
}) {
  return (
    <Head>
      <title>{`${title} - EasySLR`}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      {children}
    </Head>
  );
}

import React from "react";
import { Card, CardContent } from "../ui/card";
import { cn } from "~/lib/utils";
import { ShieldCheck } from "lucide-react";
import { Badge } from "../ui/badge";

const sectionItems = [
  {
    title: "Customizable Workflows",
    description:
      "Create custom extraction forms, define protocols, leverage AI and set up workflows that align precisely with your project requirements.",
  },
  {
    title: "AI-Enhanced Review Expert",
    isBeta: true,
    description:
      "Enhance your systematic review with our AI assistant. Functioning as both a proactive Copilot during the screening and data extraction stages, and as an Independent Reviewer alongside human experts.",
  },
  {
    title: "Collaborative Platform",
    description:
      "Collaborate seamlessly with your team on a centralized platform. Assign roles, track progress, and communicate effectively.",
  },
  {
    title: "Automated Data Extraction",
    isBeta: true,
    description:
      "Efficiently and precisely extract key information from studies, automatically linked to highlighted sources for easy verification.",
  },
  {
    title: "Full-Text Retrieval",
    description:
      "Access the full text of studies instantly with just a single click including supplementary studies.",
  },
  {
    title: "Transparent Conflict Resolution",
    description:
      "Resolve disagreements among reviewers with ease. Manage consensus and maintain the integrity of your systematic review.",
  },

  {
    title: "Comprehensive Analytics",
    description:
      "Gain valuable insights into your project's progress and performance with real-time analytics dashboard.",
  },
  {
    title: "SOC2 and ISO27001 Compliant",
    description:
      "Rest assured that your data is secure and protected. EasySLR adheres to the highest standards of data privacy and security.",
  },
];

const Features = () => {
  return (
    <div id="features" className="mx-auto mb-0 max-w-8xl px-6 pt-24 lg:px-8">
      <h2 className="text-accented mx-auto text-center text-3xl font-semibold leading-8">
        Empowering Researchers to Deliver Excellence
      </h2>
      <p className="mx-auto mt-6 max-w-3xl text-center text-lg leading-8 text-secondary-foreground">
        Conduct systematic reviews with our AI-powered platform, designed to
        help you achieve your research goals.
      </p>
      <div className="mt-9 grid auto-rows-auto grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        {sectionItems.map(({ title, description, isBeta }, index) => {
          return (
            <Card
              key={index}
              className={cn(
                "group flex flex-col overflow-clip rounded-2xl border border-muted shadow-sm transition-colors duration-300",
                index === 1 ? "row-span-2" : "",
                index === 7 ? "max-lg:col-span-2 max-md:col-span-1" : "",
                "backdrop-blur-lg duration-300 hover:border-slate-300 dark:bg-white/10 dark:hover:border-slate-400",
              )}
            >
              <CardContent className="group flex h-full flex-col p-6">
                <div>
                  <div className="flex-wradiv mb-2 flex items-center gap-2 text-xl font-medium text-foreground">
                    {title}{" "}
                    {isBeta && <Badge variant={"secondary"}>Beta</Badge>}
                  </div>
                  <p className="text-base leading-7 text-muted-foreground dark:text-slate-300">
                    {description}
                  </p>
                </div>
                {index === 1 && (
                  <div className="hidden h-full items-center justify-center md:flex">
                    <div className="relative">
                      <ShieldCheck className="h-16 w-16 text-slate-100 transition-colors duration-300 group-hover:text-slate-200" />
                      {/* <div
                        style={{
                          backgroundPosition: "200% -600%",

                          // background: "url(common/FT3.svg)",
                          // height: "232px", // or use a different unit like "rem" or "%"
                          // width: "232px",
                          backgroundImage: "url('/common/FT2.png')",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "200% -600%",
                        }}
                        className="h-60 w-60"
                      /> */}
                      {/* <Image
                        src="/common/FT2.svg"
                        width={100}
                        height={100}
                        alt="ft.svg"
                        style={{
                          backgroundSize: "200% 200%",
                        }}
                      /> */}
                    </div>
                  </div>
                )}
              </CardContent>
            </Card>
          );
        })}
      </div>
    </div>
  );
};

export default Features;
